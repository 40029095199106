import EnergyMeasurementValidator from "../domain/energy-measurement-validator.js";
import AssetHourlyEnergyFinderInterfaceClass from "../infrastructure/asset-hourly-energy-finder-interface.js";
import FillTimeSerieDataDay from "../../shared/application/utils/fill-time-serie-data-day.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.backend_interface = new AssetHourlyEnergyFinderInterfaceClass();
		this.energy_measurement_validator = EnergyMeasurementValidator;
	}

	async get_asset_day_hourly_energy(asset_id, date, fill_day = null) {
		try {
			const interface_result =
				await this.backend_interface.get_asset_day_hourly_energy(
					asset_id,
					date
				);

			if (!interface_result || interface_result.length == 0) return null;

			let validated_result = [];
			interface_result.forEach((item) => {
				const validated_item = this.energy_measurement_validator.validate(item);
				validated_result.push(validated_item);
			});

			return fill_day
				? FillTimeSerieDataDay(validated_result)
				: validated_result;
		} catch (e) {
			throw new AppError(
				"error_get_asset_day_hourly_energy",
				"general.errors.general_error",
				e
			);
		}
	}
}
