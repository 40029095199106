<template>
	<div class="full-width full-height p-xxs">
		<div class="flex-auto-full-height-content rounded-s overflow-hidden">
			<div class="header">
				<PageHeader :text="$t('header.pr_tool')" close_name="app" />
				<div class="display-flex-space-between white-bg p-sm">
					<div class="display-flex">
						<div style="min-width: 250px">
							<LocationSelect v-model="asset_id" />
						</div>

						<div v-if="loading_datepicker" class="m-l">
							<Loader size="20" />
						</div>
						<div v-else-if="data_available_from" class="m-l">
							<div>
								<Datepicker
									v-model="selected_date"
									min_view="year"
									:allow_from="data_available_from"
									:allow_to="data_available_to"
								/>
							</div>
						</div>
					</div>

					<div
						v-if="chart_series"
						class="flex-horizontal-right grey1-color text-justify"
					>
						<div
							class="full-height p-sm grey3-bg rounded-s font-s"
							style="max-width: 450px"
						>
							<div class="font-700">
								{{ $t("pr_tool.header_question_1") }}
							</div>
							<div>
								{{ $t("pr_tool.header_answer_1") }}
							</div>
						</div>

						<div
							v-if="asset_has_anomalous_pr"
							class="full-height p-sm grey3-bg rounded-s m-l font-s text-justify"
							style="max-width: 450px"
						>
							<div class="font-700">
								{{ $t("pr_tool.header_question_2") }}
							</div>
							<div>
								{{ $t("pr_tool.header_answer_2") }}
							</div>
						</div>

						<div
							v-if="$is_number(asset_monthy_average_pr)"
							class="full-height p-sm flex-center-content rounded-s m-l"
						>
							<div class="display-flex">
								<div class="flex-center-content">
									<div
										id="percentage-icon"
										class="full-height full-rounded grey1-bg flex-center-content white"
									>
										<div>%</div>
									</div>
								</div>

								<div class="m-l-sm grey1-color flex-vertical-center">
									<div>
										<div class="font-700 font-xxl">
											{{ $format_number(asset_monthy_average_pr, 0) }}%
										</div>
										<div class="font-bold font-l">
											{{ $t("pr_tool.average_pr") }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="content white-bg p-sm">
				<div
					v-if="loading_asset_monthly_pr"
					class="full-width full-height flex-center-content"
				>
					<Loader />
				</div>
				<div v-else-if="error">
					<Alert :message="error" />
				</div>
				<div
					v-else-if="!this.asset_id"
					class="full-width full-height flex-center-content"
				>
					<div class="text-center grey1-color" style="width: 300px">
						<div class="font-700 font-xl">{{ $t("pr_tool.intro_title") }}</div>
						<div class="m-t font-l">{{ $t("pr_tool.intro_subtitle") }}</div>
					</div>
				</div>
				<div v-else-if="asset_monthly_pr">
					<ColumnChart
						width="100%"
						height="600px"
						:series="chart_series"
						:show_tooltip="true"
						:tooltip_value_formatter="
							(value, serie_name) => {
								switch (serie_name) {
									case 'PR':
										if (value == null) return null;
										else if (value >= 120)
											return this.$t('pr_tool.abnormal_pr');
										else if (value > 98) return '> 98%';
										else return this.$format_number(value, 0) + '%';
										break;
									case 'GHI':
										return this.$is_number(value)
											? this.$format_number(value, 0) + ' kWh/m&sup2;'
											: null;
										break;
									default:
										return null;
								}
							}
						"
						:tooltip_hide_markers="true"
						:categories="
							chart_categories.map((item) => $format_date_to_month_string(item))
						"
						:bar_colors="[
							function ({ value, seriesIndex, w }) {
								return value >= 120 ? 'var(--grey2)' : 'var(--blue1)';
							},
							'var(--warning-color)',
							'var(--grey1)'
						]"
						:multiple_y_axis="true"
						:yaxis="chart_y_axis_data"
						:x_axis_formatter="(value) => value"
						:show_toolbar="false"
						column_width="60%"
						:show_legend="true"
						legend_positon="bottom"
						:custom_chart_options="{
							markers: {
								size: 7,
								shape: 'square'
							},
							stroke: {
								width: 3
							}
						}"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import Loader from "@loader";
import Alert from "@alert";
import LocationSelect from "@form_components/LocationSelect2.vue";
import Datepicker from "@form_components/Datepicker.vue";
import ColumnChart from "@sub_components/charts/ColumnChart.vue";

import moment from "moment";
import AssetEnergyAvailableDateFinderClass from "@src/asset-energy-data/application/asset-energy-available-date-finder.js";
import AssetMonthlyPrFinderClass from "@src/asset-energy-data/application/asset-monthy-pr-finder.js";
import AssetReanalysisWeatherFinderClass from "@src/asset-weather/application/asset-reanalysis-weather-finder.js";
import UserPrLimitFinderClass from "@src/user/application/pr-limit/user-pr-limit-finder.js";

const AssetEnergyAvailableDateFinder =
	new AssetEnergyAvailableDateFinderClass();
const AssetMonthlyPrFinder = new AssetMonthlyPrFinderClass();
const AssetReanalysisWeatherFinder = new AssetReanalysisWeatherFinderClass();
const UserPrLimitFinder = new UserPrLimitFinderClass();

export default {
	name: "PrTool",
	components: {
		PageHeader,
		Loader,
		Alert,
		LocationSelect,
		Datepicker,
		ColumnChart
	},
	data() {
		return {
			asset_id: null,
			loading_datepicker: false,
			data_available_from: null,
			data_available_to: null,
			selected_date: null,
			loading_asset_monthly_pr: false,
			error: null,
			asset_monthly_pr: null,
			chart_series: null,
			chart_categories: null,
			user_pr_limit: null
		};
	},
	async mounted() {
		try {
			const user_pr_limit_data = await UserPrLimitFinder.get_user_pr_limit();
			if (user_pr_limit_data && user_pr_limit_data.pr_limit)
				this.user_pr_limit = user_pr_limit_data.pr_limit.pr_lowest_limit;
		} catch (error) {
			this.error = error;
		}
	},
	methods: {
		async set_data_avaialable_dates() {
			this.error = null;

			this.data_available_from = null;
			this.data_available_to = null;
			this.selected_date = null;

			if (this.asset_id) {
				try {
					this.loading_datepicker = true;

					this.data_available_from =
						await AssetEnergyAvailableDateFinder.get_asset_energy_data_first_date(
							this.asset_id
						);
					this.data_available_to =
						await AssetEnergyAvailableDateFinder.get_asset_energy_data_last_date(
							this.asset_id
						);

					this.selected_date = this.data_available_to;
				} catch (error) {
					this.error = error;
				} finally {
					this.loading_datepicker = false;
				}
			}
		},
		async get_asset_monthly_pr() {
			if (!this.selected_date) return null;

			this.error = null;
			this.loading_asset_monthly_pr = true;
			this.asset_monthly_pr = null;
			this.chart_categories = null;
			this.chart_series = null;

			try {
				const date_from = moment(this.selected_date).startOf("year").toDate();
				const date_to = moment(this.selected_date).endOf("year").toDate();

				this.asset_monthly_pr = await AssetMonthlyPrFinder.get_asset_monthly_pr(
					this.asset_id,
					date_from,
					date_to
				);

				const asset_reanalysis_data =
					await AssetReanalysisWeatherFinder.get_asset_monthly_reanalysis_weaher(
						this.asset_id,
						date_from,
						date_to
					);

				let chart_pr_data = {
					name: "PR",
					type: "column",
					data: []
				};
				let chart_ghi_data = {
					name: "GHI",
					type: "line",
					data: []
				};
				this.chart_categories = [];

				for (let index = 0; index < 12; index++) {
					let monht_date =
						index == 0 ? date_from : moment(date_from).add(index, "months");
					this.chart_categories.push(monht_date);

					const month_pr = this.asset_monthly_pr.find((item) =>
						moment(item.month).isSame(monht_date, "month")
					);
					let pr_value;
					if (month_pr == null) pr_value = null;
					else pr_value = month_pr.pr;
					chart_pr_data.data.push(pr_value);

					const month_reanalysis = asset_reanalysis_data.find((item) =>
						moment(item.time).isSame(monht_date, "month")
					);
					chart_ghi_data.data.push(
						month_reanalysis ? month_reanalysis.ghi : null
					);
				}

				this.chart_series = [chart_pr_data, chart_ghi_data];

				if (this.$is_number(this.user_pr_limit)) {
					let chart_user_pr_limit = {
						name: this.$t("pr_tool.pr_limit"),
						type: "line",
						data: [],
						zIndex: 0
					};
					for (let index = 0; index < 12; index++) {
						chart_user_pr_limit.data.push(this.user_pr_limit);
					}
					this.chart_series.push(chart_user_pr_limit);
				}
			} catch (error) {
				this.error = error;
			} finally {
				this.loading_asset_monthly_pr = false;
			}
		}
	},
	computed: {
		asset_monthy_average_pr() {
			if (!this.asset_monthly_pr) return null;

			let months_with_pr = [];
			this.asset_monthly_pr.forEach((item) => {
				if (this.$is_number(item.pr) && item.pr < 98)
					months_with_pr.push(item.pr);
			});

			return months_with_pr.length > 0
				? months_with_pr.reduce((a, b) => a + b) / months_with_pr.length
				: null;
		},
		asset_has_anomalous_pr() {
			if (!this.asset_monthly_pr) return null;

			return this.asset_monthly_pr.find((item) => item.pr >= 120)
				? true
				: false;
		},
		chart_y_axis_data() {
			const format_number = this.$format_number;
			const is_number = this.$is_number;

			let result = [
				{
					seriesName: "PR",
					labels: {
						formatter: (value) => {
							if (value == null) return null;
							else return format_number(value, 0) + "%";
						}
					},
					min: 0,
					max: 100
				},
				{
					seriesName: "GHI",
					show: true,
					opposite: true,
					labels: {
						formatter: (value) =>
							is_number(value) ? format_number(value, 0) + " kWh/m²" : null
					}
				}
			];

			if (this.$is_number(this.user_pr_limit))
				result.push({
					seriesName: this.$t("pr_tool.pr_limit"),
					min: 0,
					max: 100,
					show: false,
					labels: {
						formatter: (value) => format_number(value, 0) + "%"
					}
				});

			return result;
		}
	},
	watch: {
		asset_id(new_value) {
			this.asset_monthly_pr = null;
			this.chart_categories = null;
			this.chart_series = null;

			this.set_data_avaialable_dates();
		},
		selected_date(new_date) {
			this.get_asset_monthly_pr();
		}
	}
};
</script>

<style scoped>
#percentage-icon {
	width: 60px;
	height: 60px;
	font-size: 30px;
}
</style>
