<template>
	<div>
		<div class="display-flex-space-between">
			<div>
				<span>Custom forecasting</span>
			</div>
			<CustomCheckbox v-model="custom_forecasting.enabled" />
		</div>

		<div v-if="custom_forecasting.enabled" class="full-width m-t">
			<div class="flex-horizontal-right">
				<button
					class="btn btn-primary"
					v-on:click="add_custom_forecasting_option"
				>
					{{ $t("general.add") }}
				</button>
			</div>
			<DataTable
				:items="custom_forecasting.model_order"
				:fields="[
					{
						key: 'change_order'
					},
					{
						key: 'model_type',
						label: 'Model type'
					},
					{
						key: 'weather_provider_name',
						label: 'Provider'
					},
					{
						key: 'options'
					}
				]"
			>
				<template #cell(change_order)="data">
					<div
						v-if="custom_forecasting.model_order.length > 1"
						class="flex-center-content"
					>
						<div>
							<div
								v-if="data.index != 0"
								v-on:click="move_up_model_order(data.index)"
								class="cursor-pointer"
							>
								<Icon icon="caret-up" size="22" />
							</div>
							<div
								v-if="data.index != custom_forecasting.model_order.length - 1"
								v-on:click="move_down_model_order(data.index)"
								class="cursor-pointer"
							>
								<Icon icon="caret-down" size="22" />
							</div>
						</div>
					</div>
				</template>

				<template #cell(model_type)="data">
					<Multiselect
						v-model="data.item.model_type"
						:multiple="false"
						label="label"
						track_by="key"
						:can_clear="false"
						:close_on_select="true"
						:options="[
							{
								key: 'statistical',
								label: 'Statistical'
							},
							{
								key: 'analytic',
								label: 'Analytic'
							}
						]"
					/>
				</template>

				<template #cell(weather_provider_name)="data">
					<Multiselect
						v-model="data.item.weather_provider_name"
						:multiple="false"
						label="label"
						track_by="key"
						:can_clear="false"
						:close_on_select="true"
						:options="[
							{
								key: 'gfs',
								label: 'GFS'
							},
							{
								key: 'soda',
								label: 'SODA'
							}
						]"
					/>
				</template>

				<template #cell(options)="data">
					<div
						class="danger-color cursor-pointer"
						v-on:click="remove_custom_forecasting_element(data.index)"
					>
						<Icon icon="trash" />
					</div>
				</template>
			</DataTable>
		</div>
	</div>
</template>

<script>
import CustomCheckbox from "../../../../../sub_components/form_components/CustomCheckbox.vue";
import DataTable from "../../../../../sub_components/DataTable.vue";
import Multiselect from "../../../../../sub_components/form_components/Multiselect.vue";

export default {
	name: "EnergySystemCustomForeasting",
	components: {
		CustomCheckbox,
		DataTable,
		Multiselect
	},
	props: {
		value: Object
	},
	data() {
		return {
			custom_forecasting: this.value
		};
	},
	methods: {
		add_custom_forecasting_option() {
			this.custom_forecasting.model_order.push({
				model_type: null,
				weather_provider_name: null
			});
		},
		remove_custom_forecasting_element(index_to_remove) {
			if (index_to_remove !== -1) {
				this.custom_forecasting.model_order.splice(index_to_remove, 1);
			}
		},
		array_move(arr, old_index, new_index) {
			if (new_index >= arr.length) {
				var k = new_index - arr.length + 1;
				while (k--) {
					arr.push(undefined);
				}
			}
			arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
			return arr; // for testing
		},
		move_up_model_order(index) {
			this.custom_forecasting.model_order = this.array_move(
				this.custom_forecasting.model_order,
				index,
				index - 1
			);
		},
		move_down_model_order(index) {
			this.custom_forecasting.model_order = this.array_move(
				this.custom_forecasting.model_order,
				index,
				index + 1
			);
		}
	},
	watch: {
		"custom_forecasting.enabled"(new_value) {
			if (!new_value) this.custom_forecasting.model_order = [];
		}
	}
};
</script>
