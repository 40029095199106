import Joi from "joi";
import Validator from "@validators/validator";

const energy_system_schema = Joi.object({
	energy_measurements_download: Joi.object({
		optimize_all: Joi.boolean()
	}),
	custom_forecasting: Joi.object({
		enabled: Joi.boolean().required(),
		model_order: Joi.array()
			.required()
			.min(1)
			.items(
				Joi.object({
					model_type: Joi.string().valid("statistical", "analytic").required(),
					weather_provider_name: Joi.string().valid("gfs", "soda").required()
				})
			)
	})
});
export default new Validator(
	energy_system_schema,
	"entities.energy_system_custom_config."
);
